import AttendanceBaseService from '../base/AttendanceBaseService';

export class PersonApi extends AttendanceBaseService {
    constructor() {
        super("personDays");
    }

    /**
   * 获取用户假期
   */
    public getList(userId: any): Promise<any> {
        return this.request('myVacation/list?userId='+userId, this.rest.METHOD_GET, null);
    }
}