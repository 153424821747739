
import { defineComponent } from "vue";

export default defineComponent({
  name: "HolidayCard",
  components: {},
  props: {
    HolidayCardObj: Object,
  },
  emits: [],
  data() {
    return {};
  },
  methods: {},
});
