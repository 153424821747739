import { PersonApi } from '@/api/attendance/person/PersonApi';

export class PersonService {
    private api = new PersonApi();

    public getList(params: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.api.getList(params).then((res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        });
    } 
}