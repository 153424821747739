
import { defineComponent, reactive, onMounted, ref } from "vue";
import HolidayCard from "@/components/ListCard/HolidayCard.vue";
import CardLoading from "@/components/ListCard/CardLoading.vue";
import { useRouter } from "vue-router";
import Header from "@/components/Header.vue";
import Utils from "@/utils/Utils";
import SystemUtils from "@/utils/SystemUtils";
import { PersonService } from "@/services/attendance/person/PersonService";
export default defineComponent({
  name: "MyHolidayPage",
  components: {
    Header,
    HolidayCard,
    CardLoading,
  },
  setup() {
    const router = useRouter();
    let islist = ref(true); //是否显示加载中的标识
    const holidayList = reactive({ data: new Array() });
    const personApi = new PersonService();
    const initData = () => {
      getData();
    };
    const getData = () => {
      personApi
        .getList(SystemUtils.loginUser.id)
        .then((res: any) => {
          holidayList.data = res.data;
          islist.value = false;
        })
        .catch(() => {
          islist.value = false;
        });
    };
    const holidayDetail = (HolidayObj: any) => {
      router.push({
        name: "MyHolidayDetailList",
        params: {
          leaveTypeId: HolidayObj.typeId,
          typeName: HolidayObj.typeName,
          years: HolidayObj.years,
          userId: SystemUtils.loginUser.id,
        },
      });
    };

    const doRefresh = (event: any) => {
      getData();
      setTimeout(() => {
        event.target.complete();
      }, 600);
    };

    onMounted(() => {
      initData();
    });
    return {
      btnIconRight: ["iconleijigongshi"],
      btnSizeRight: ["34px"],
      doRefresh,
      holidayList,
      islist,
      holidayDetail,
    };
  },
});
